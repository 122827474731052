<template>
  <v-app id="inspire">
    <router-view></router-view>
    <confirm-dialog />
  </v-app>
</template>

<script>
import { getCookie } from '@/utils'

export default {
  watch: {
    '$route' () {
      if (this.$router.currentRoute.name != 'login') {
        const token = getCookie('x-access-token')
        if (!token) {
          this.$router.push({ name: 'login' })
        }
      }
    }
  }
}
</script>
