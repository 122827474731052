import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue' 
// import Login from '../components/Login/KakaoLogin.vue' 
Vue.use(VueRouter)

const routes = [
  {
    path: '/', 
    name: 'login', 
    component: Login,
  }, 
  // {
  //   path: '/manage_login',
  //   name: 'manage_login',
  //   component: () => import('../views/ManageLogin.vue'),
  //
  // },
  {
    path: '/main',
    name: 'main',
    component: () => import('../views/Main.vue'),
    children: [
      {
        path: '/TrainData/:botId?',
        name: 'train_data',
        component: () => import(/* webpackChunkName: "about" */ '../components/TrainData/TrainData.vue'),
        props : true,
        children: [
          {
            path: '/trainList',
            name: 'trainList',
            component: () => import(/* webpackChunkName: "about" */ '../components/TrainData/TrainDataList.vue'),
          },
        ]
      },
      {
        path: '/ModelLog/:botId?',
        name: 'modellog',
        props : true,
        component: () => import(/* webpackChunkName: "about" */ '../components/ModelLog/ModelLog.vue'),
      },
      {
        path: '/BotLog',
        name: 'botlog',
        component: () => import(/* webpackChunkName: "about" */ '../components/BotLog/BotLog.vue'),        
      }
    ]
  }
]

const router = new VueRouter({
  // mode : "history",
  routes
})

export default router
