import Constant from '../constant'
import Vue from "vue";

export default {
  [Constant.USER_INFO] : (store, payload) => {
    store.commit(Constant.USER_INFO, payload)
  },
  MODAL_ACTION : (store,payload) => {
    store.commit("MODAL_ACTION", payload);
  },
  async startDownload(store, payload) {
    const downid = payload.requestid + payload.type
    const type = payload.type
    const version = payload.version
    const fullname = payload.fullname

    for (const target of store.state.downloadList) {
      if (target.id === downid && target.status === "start") {
        // 이미 다운중이면 팝업만 보여줌
        store.commit("toggleDownload")
        return
      } else if (target.id === downid && target.status === "done") {
        // 다운로드 완료된 동일건이 있다면 삭제
        store.commit("delDownload", downid)
      }
    }

    const typeStr = {'train': '학습', 'test': '평가', 'regression': '리그레션'}
    // 중복 건이 없다면 다운로드 진행
    store.commit("addDownload", {
      id: downid,
      botId: fullname,
      version: version,
      type: typeStr[type],
      status: "start",
    })
    // downlaod
    const fileDownload = require("js-file-download")
    await Vue.prototype.$http.get(process.env.VUE_APP_SNAPSHOT_DOWNLOAD, {
      responseType: "blob",
      params: payload
    }).then(res => {
      store.commit("doneDownload", downid)
      const data = res.data
      let filename = fullname + "_" + version + "_" + type + ".tsv"
      fileDownload(data, filename)
      let alertMsg = ''
      if (res.status == 206) {
        alertMsg = '검증 API 호출 횟수가 설정 값을 초과하여 검증 필드를 제외하였습니다.'
      }
      store.commit("setAlertMsg", alertMsg)
    })
  }
}
