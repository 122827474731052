import Constant from '../constant'

export default {
  [Constant.USER_INFO] : (state, payload) => {
    if (Object.keys(payload).length === 0 && payload.constructor === Object) {
      state.user = {}
    } else {
      state.user.customer = payload.customer
      state.user.appid = payload.appid
    }
  },
  "MODAL_ACTION" : (state, payload) => {
    const { isShow , callback , text } = payload;
    state.modal = Object.assign({},state.modal,{
      isShow,
      callback,
      text
    });
  },
  toggleDownload(state) {
    state.downloadDialog = !state.downloadDialog
  },
  addDownload(state, payload) {
    if (!state.downloadDialog) {
      state.downloadDialog = true
    }
    state.downloadList.push(payload)
  },
  doneDownload(state, payload) {
    const target = state.downloadList.find(item => item.id === payload)
    if (target) target.status = "done"

    if (!state.downloadDialog) {
      state.downloadDialog = true
    }
  },
  delDownload(state, payload) {
    const idx = state.downloadList.findIndex(item => item.id === payload)
    if (idx > -1) state.downloadList.splice(idx, 1)
  },
  setAlertMsg(state, payload) {
    state.alertMsg = payload
  },
}
