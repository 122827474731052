<template>
    <v-dialog
        max-width="580"
        v-model="isShow"
      >
        <v-card>
           <v-card-text class="text-xs-left subheading">
            <v-container> 
              <v-layout column>
                <v-flex>
                  <v-text-field v-model="text" />
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                @click="closeEvent"
            >
                취소
            </v-btn>
            <v-btn
                color="primary"
                @click="nextEvent(callback)"
            >
                확인
            </v-btn>             
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

export default {

    name : "ConfirmDialog",
    computed : {
        callback(){
            return this.$store.state.modal.callback;
        },
        isShow(){
            return this.$store.state.modal.isShow;
        },
        text(){
            return this.$store.state.modal.text;
        }
    },
    methods : {
        closeEvent(){
            this.$store.dispatch("MODAL_ACTION",{
                isShow : false,
                callback : () => {}
            })
        },
        async nextEvent( func){
            await func();
            this.closeEvent();
        }
    }

}

</script>