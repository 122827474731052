export default {
    user: {},
    timerId: null,
    predefined_set: {},
    modal : {
      isShow : false,
      callback : null,
      text : null,
    },
    downloadDialog: false,
    downloadList: [],
    alertMsg: '',
  }
