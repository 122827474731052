import Vue from 'vue'
import App from './App.vue'
import { deleteCookie } from '@/utils'
import qs from 'qs'
import vuetify from './plugins/vuetify';

import "./styles/style.scss";
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import axios from 'axios'
import store from './store'
import router from './router'
// import { HeatMapPlugin } from '@syncfuion/ej2-vue-heatmap';
// import { HeatMapComponent, HeatMapPlugin } from '@syncfusion/ej2-vue-heatmap';
import VueApexCharts from 'vue-apexcharts'
import ConfirmDialog from "./views/common/ConfirmDialog.vue"

import VueEditableGrid from 'vue-editable-grid'
import 'vue-editable-grid/dist/VueEditableGrid.css'

// import VueTableDynamic from 'vue-table-dynamic'

// import VueGoodTablePlugin from 'vue-good-table';
//import 'vue-good-table/dist/vue-good-table.css'

Vue.config.productionTip = false
Vue.prototype.$http = axios
axios.interceptors.response.use(response => {
  return response
}, error => {
  if (error.response.status == 401) {
    // console.log('deleteCookie')
    // deleteCookie('x-access-token')
    // router.replace({ name: 'login'})
  }
  return Promise.reject(error.response)
})
axios.defaults.paramsSerializer = params => {
	return qs.stringify(params, { indices: false })
}

Vue.prototype.$eventBus = new Vue()
// Vue.use(HeatMapPlugin);
// Vue.component(HeatMapPlugin.name, HeatMapComponent);
Vue.use(VueApexCharts)
// Vue.use(VueTableDynamic)
Vue.component('apexchart', VueApexCharts);
Vue.component("confirm-dialog", ConfirmDialog);


Vue.component('vue-editable-grid', VueEditableGrid)

// Vue.use(VueGoodTablePlugin);
Vue.use(vuetify, {
  iconfont: 'mdi'
})

new Vue({
  vuetify,
  router,
  store, 
  render: h => h(App)
}).$mount('#app')
