export function setCookie (name, value, exp) {


  
  let date = new Date();
  // date.setTime(date.getTime() + exp * 60 * 60 * 100000);
  date.setTime(date.getTime() + exp * 60 * 60 * 10000); 
  // console.log('setCookie')
  // console.log(name + '=' + value + ';expires=' + date.toUTCString() + ';path=/')
  // document.cookie = name + '=' + value + ';expires=' + date.toUTCString() + ';path=/';

  let tInfo = name + '=' + value + ';expires=' + date.toUTCString() + ';path=/';
  // console.log(tInfo)
  sessionStorage.setItem("tokenInfo", tInfo);
  // console.log('sessionStorage set OK')

}

export function getCookie (name) {
  // let value = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
  let value = sessionStorage.getItem('tokenInfo').match('(^|;) ?' + name + '=([^;]*)(;|$)');
  // return value ? value[2] : window.getItem('token')
  // console.log()
  // console.log('getSessionStorage :',  value)
  return value ? value[2] : null 
} 


export function deleteCookie (name) {
  // document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  sessionStorage.removeItem('tokenInfo')
}
export function decodeToken (token) {
  var base64Url = token.split('.')[1]
  var base64 = base64Url.replace('-', '+').replace('_', '/')
  return JSON.parse(window.atob(base64))
}

export function getDatetime(timestamp) {
  let d = new Date(timestamp*1000)
  return d.getFullYear() +
    '-' + ('0' + (d.getMonth()+1)).slice(-2) +
    '-' + ('0' + d.getDate()).slice(-2) + 
    ' ' + ('0' + d.getHours()).slice(-2) +
    ':' + ('0' + d.getMinutes()).slice(-2) +
    ':' + ('0' + d.getSeconds()).slice(-2)
}


export function globalCatch(error) {
  // alert('비정상 접근입니다.')
  // deleteCookie('x-access-token')
  // this.$store.dispatch(Constant.USER_INFO, {})
  // this.$router.push({ name: 'login'})        
}

export function commonSetTimeout() {

}
export function commonClearTimeout() {

}
//EOF